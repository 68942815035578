import { mapGetters, mapActions } from "vuex";
export const animationHelper = {
  data() {
    return {
      mappingClasses: {
        5: "sky",
        6: "earth",
        7: "sea"
      },

      BGanimationClass: {
        sky: "none",
        earth: "none",
        sea: "none"
      },

      BGanimationOnlyFade: "none",
      titleAnimationClass: "none",
      finalTranslate: 0,
      transitionClass: "standard"
    };
  },
  computed: {
    ...mapGetters(["landingIndex", "subIndex", "autoScroll"])
  },

  beforeMount() {
    const that = this;
    let timeToAnimate = 2000;

    if (this.landingIndex > 5) {
      // set slower if button clicked is terra o mare so that transition is not too fast
      // this happens when circle btn are cliked

      if (this.autoScroll) {
        this.setSlowTranslate();
      }
    }

    if (!this.autoScroll && this.landingIndex === this.myIndex) {
      // quando riemergo (ovvero autoscroll = false) nella cover corrente setto autoscroll di nuovo a true e animo immediatamente
      timeToAnimate = 0;
      this.toggleAutoScroll(true);
    }

    setTimeout(this.animateBgByIndex, timeToAnimate, this.landingIndex);

    this.setFinalTranslate();
    this.titleAnimationClass = "none";
  },
  watch: {
    //MIXIN
    landingIndex(newIndex, oldIndex) {
      const that = this;

      // index changed reset all animation
      this.BGanimationClass.sky = "none";
      this.BGanimationClass.earth = "none";
      this.BGanimationClass.sea = "none";
      this.BGanimationOnlyFade = "none";

      const moreThanOne = Math.abs(newIndex - oldIndex) > 1;

      // this is triggered by menu
      if (moreThanOne) {
        if (this.autoScroll) {
          this.setSlowTranslate();
        }
      }

      this.setFinalTranslate();

      if (newIndex === this.myIndex) {
        // reanimate if current index, immediatly if moving by 1
        let timeToAnimate = moreThanOne ? 1500 : 0;
        setTimeout(this.animateBgByIndex, timeToAnimate, newIndex);
      }
    }
  },

  methods: {
    setFinalTranslate() {
      const mapTranslateValue = {
        5: 0,
        6: 110,
        7: 220
      };

      if (this.autoScroll) {
        const that = this;
        setTimeout(function() {
          that.finalTranslate = -mapTranslateValue[that.landingIndex];
        }, 10);
      } else {
        this.finalTranslate = -mapTranslateValue[this.landingIndex];
      }

      // return -mapTranslateValue[this.landingIndex];
    },
    setSlowTranslate() {
      const that = this;
      this.transitionClass = "slower";

      setTimeout(function() {
        that.transitionClass = "standard";
      }, 2000);
    },
    animateBgByIndex(index) {
      this.BGanimationClass[this.mappingClasses[index]] = "animateMove";

      if (this.mappingClasses[index] === "sea") {
        this.BGanimationOnlyFade = "fadeSea";
      }
    },

    immersionBtnClick(to) {
      const that = this;
      this.toggleAutoScroll(false);

      this.BGanimationClass[this.mappingClasses[this.myIndex]] = "animateScale";
      // since title is rotated on mobile has is own class
      this.titleAnimationClass = "animateScaleTitle";

      setTimeout(function() {
        that.$router.push({ name: to });
      }, 1000);
    },
    ...mapActions(["toggleAutoScroll"])
  }
};
