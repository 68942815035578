/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 330 330',
    data: '<path pid="0" d="M233.25 306.001L127.5 165.005 233.25 24.001c4.971-6.628 3.627-16.03-3-21-6.627-4.971-16.03-3.626-21 3L96.75 156.005a15 15 0 000 18l112.5 149.996a14.975 14.975 0 0012.012 6.001c3.131 0 6.29-.978 8.988-3.001 6.628-4.971 7.971-14.373 3-21z"/>'
  }
})
