/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 16,
    height: 16,
    viewBox: '7 7 26 25',
    data: '<g filter="url(#filter0_d_726_1930)"><path pid="0" d="M8 31L31.014 8M8.986 8L32 31" _stroke="#fff" stroke-width="2"/></g><defs><filter id="filter0_d_726_1930" x=".293" y=".293" width="39.414" height="38.415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="3.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_726_1930"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_726_1930" result="shape"/></filter></defs>'
  }
})
