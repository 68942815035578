/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ctm-logo': {
    width: 91,
    height: 62,
    viewBox: '0 0 91 62',
    data: '<path pid="0" d="M80.81 55.618c.41 2.603-1 3.836-2.82 4.567v.822H91v-.822c-1.774-.73-3.184-1.964-3.594-4.887l-3.32-22.742c-.41-2.694.773-4.43 2.911-4.977v-.823h-10.28L68.71 48.311l-7.233-21.555H50.652v.822c1.865.412 3.457 2.238 3.048 4.978L50.152 55.71c-.319 2.055-1.774 3.654-3.594 4.476v.822h8.78v-.822c-1.911-.73-3.094-2.42-2.775-4.476l3.593-23.7 9.735 29.454h.773L77.263 32.1l3.548 23.518zM17.622.463C7.837.463 0 8.46 0 18.667c0 10.254 6.84 17.03 14.858 17.03 6.297 0 11.869-4.111 13.183-11.61l-.544-.18c-2.038 4.11-5.345 6.414-9.694 6.414-7.293 0-11.869-6.188-11.869-15.268 0-6.865 3.307-11.699 8.517-11.699 4.077 0 7.112 3.117 8.47 8.763h.454l4.303-9.892C25.232 1.322 21.563.463 17.622.463z" _fill="#fff"/><path pid="1" d="M31.142 12.249c-1.716 0-2.89-.498-3.748-1.268h-.496l-2.032 10.28.903.18c2.89-4.437 5.779-7.154 8.352-7.154 1.76 0 2.528.815 2.528 2.083v24.952c0 1.948-.993 3.352-3.16 4.076v.815h12.912v-.815c-2.032-.724-3.07-2.128-3.07-4.076V16.37c0-1.268.722-2.083 2.528-2.083 2.618 0 5.462 2.717 8.397 7.155l.767-.181-1.941-10.28h-.497c-.767.77-2.076 1.268-3.702 1.268H31.141z" _fill="#fff"/>'
  }
})
