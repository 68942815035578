import Vue from "vue";
import App from "./App.vue";
import i18n from "./language";

// Store
import store from "./store";

import router from "./router";
// access globaly to $baseUrl
Vue.prototype.$baseUrl = process.env.BASE_URL;
Vue.prototype.$retina = window.devicePixelRatio > 1.5;
Vue.prototype.$isProduction =
  process.env.NODE_ENV === "production" &&
  !process.env.VUE_APP_PATH.includes("test");

Vue.config.productionTip = false;

import "@/compiled-icons";
// Svg icon
import VueSVGIcon from "vue-svgicon";
import "vue-svgicon/dist/polyfill";
Vue.use(VueSVGIcon);

// Vue3 touch events
import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

// Vue browser detect
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

// Social sharing
import SocialSharing from "vue-social-sharing";
Vue.use(SocialSharing);

// youtube player wrapper for vue
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

// Vue meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

// Js Media queries
import tvaMq from "./tvaMq";
Vue.use(tvaMq, {
  mobile: 767,
  tablet: 1024,
  desktop: 1440,
  large: Infinity
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
