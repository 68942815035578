/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 16,
    height: 16,
    viewBox: '100 25 12 75',
    data: '<path pid="0" d="M105.293 74.707a1 1 0 001.414 0l6.364-6.364a1 1 0 10-1.414-1.414L106 72.586l-5.657-5.657a1 1 0 10-1.414 1.414l6.364 6.364zM105 34v40h2V34h-2z" _fill="#fff"/>'
  }
})
