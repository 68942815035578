<template>
  <main id="app" :class="[$tvaMq]" :version="version">
    <transition name="fade" mode="out-in">
      <!-- TODO: remove splash when not necessary anymore -->
      <!-- <SplashPage v-if="$isProduction" />
      <router-view v-else /> -->
      <router-view />
    </transition>
    <div
      :class="['gradient-background', transitionClass]"
      :style="{ '--ctm-translate': finalTranslate }"
    ></div>
  </main>
</template>

<script>
import { animationHelper } from "@/mixins/bg-animationHelper";
// import SplashPage from "./views/SplashPage.vue";
import { version } from "@/../package.json";
export default {
  // components: { SplashPage },
  name: "App",
  mixins: [animationHelper],
  beforeCreate() {
    let lang;
    if (this.$route.params.lang) {
      lang = this.$route.params.lang;
    } else {
      lang = "IT";
      this.$route.params.lang = "IT";
    }
    this.$i18n.locale = lang.toLowerCase();

    // Set html language attribute
    document.querySelector("html").setAttribute("lang", lang.toLowerCase());
  },
  data() {
    return {
      version
    };
  },
  mounted() {
    this.setVHProperty();

    window.addEventListener("resize", this.setVHProperty);
    window.addEventListener("orientationchange", this.setVHProperty);
  },
  watch: {
    $route(to) {
      if (this.$route.params.lang) {
        this.$i18n.locale = to.params.lang.toLowerCase();
      }
    }
  },
  methods: {
    setVHProperty() {
      if (this.$tvaMq === "mobile") {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  .gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    transform: translateY(calc(var(--ctm-translate) * 1vh));
    transform: translateY(calc(var(--vh, 1vh) * var(--ctm-translate)));
    transition: transform ease-in-out 1s;
    height: 320vh;
    height: calc(var(--vh, 1vh) * 320);
    background: linear-gradient(
      0deg,
      #131e61 0%,
      #6f76ff 20%,
      #c8c988 37%,
      #e0e0bb 65%,
      #0b8bff 80%,
      #5f91f3 100%
    );

    &.standard {
      transition-duration: 1s;
    }

    &.slower {
      transition-duration: 2.5s;
    }
  }
}
</style>

<style lang="scss">
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
sub {
  vertical-align: sub;
  font-size: 75%;
  line-height: 75%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overscroll-behavior: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a,
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}
button {
  background-color: transparent;
  border: none;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
}

strong {
  font-weight: bold;
}

*,
::before,
::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 2500px) {
  html {
    font-size: 150%;
  }
}

@media screen and (-webkit-max-device-pixel-ratio: 1.5) {
  html {
    // Do this to prevent things from looking squeezed on small, low res devices
    font-size: 85%;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  html {
    // Do this to prevent things from looking squeezed on small, low res devices
    font-size: 70%;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
  // color: #2c3e50;
}

// Terna Banner Cookie
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  color: #fff !important;
}

#CybotCookiebotDialogHeader {
  display: none !important;
}
</style>
