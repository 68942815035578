import content from "../../assets/content/content_IT.json";
const state = {
  landingIndex: 0,
  autoScroll: true,
  isWheelListenerInitialized: false,
  // indexLimit: {
  //   sky: (Object.keys(content.sky.scenes).length + 1) * 0.1 + 5,
  //   earth: (Object.keys(content.earth.scenes).length + 1) * 0.1 + 6,
  //   sea: Object.keys(content.sea.scenes).length * 0.1 + 7
  // },
  isShareOpen: false,
  videoData: null
};

const mutations = {
  increment(state, n) {
    // let tmp = parseFloat(state.landingIndex + n);
    // if (tmp !== state.indexLimit.sea) {
    //   tmp = parseFloat(tmp.toPrecision(2));
    //   if (tmp === state.indexLimit.sky || tmp === state.indexLimit.earth) {
    //     tmp = parseInt(tmp + 1);
    //   }
    // state.landingIndex = parseFloat(tmp.toPrecision(2));
    // }

    if (state.landingIndex < 7) {
      state.landingIndex += n;
    }
  },
  decrement(state, n) {
    // let tmp = parseFloat(state.landingIndex - n);
    // if (tmp === 5.9 || tmp === 6.9) {
    //   tmp = parseInt(tmp);
    // }
    // state.landingIndex = parseFloat(tmp.toPrecision(2));
    if (state.landingIndex > 0) {
      state.landingIndex -= n;
    }
  },
  setIndexFromMenu(state, value) {
    state.landingIndex = value;
  },
  setWheelListener(state, value) {
    state.isWheelListenerInitialized = value;
  },
  toggleAutoScroll(state, value) {
    state.autoScroll = value;
  },
  toggleShare(state, value) {
    state.isShareOpen = value;
  },
  setVideoData(state, value) {
    state.videoData = value;
  }
};

const getters = {
  landingIndex: state => parseInt(state.landingIndex),
  isWheelListenerInitialized: state => state.isWheelListenerInitialized,
  // subIndex: state => {
  //   return parseInt(
  //     parseFloat(
  //       (state.landingIndex - Math.floor(state.landingIndex)).toPrecision(1)
  //     ) * 10
  //   );
  // },
  autoScroll: state => state.autoScroll,
  isShareOpen: state => state.isShareOpen,
  getVideoData: state => state.videoData
};

const actions = {
  increment({ commit, state }) {
    // if (state.landingIndex >= 5) {
    //   commit("increment", 0.1);
    // } else {
    commit("increment", 1);
    // }
  },
  decrement({ commit, state }) {
    // if (state.landingIndex > 5) {
    //   commit("decrement", 0.1);
    // } else {
    commit("decrement", 1);
    // }
  },
  setIndexFromMenu({ commit }, value) {
    commit("setIndexFromMenu", value);
  },
  setWheelListener({ commit }, value) {
    commit("setWheelListener", value);
  },
  toggleAutoScroll({ commit }, value) {
    commit("toggleAutoScroll", value);
  },
  toggleShare({ commit }, value) {
    commit("toggleShare", value);
  },
  setVideoData({ commit }, value) {
    commit("setVideoData", value);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
