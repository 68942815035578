/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 19,
    height: 42,
    viewBox: '0 0 19 42',
    data: '<g filter="url(#filter0_d_1955_188)"><path pid="0" d="M10.645 35V16.874h-2.28V35h2.28zM7.605 9.35c0 1.102.836 1.9 1.9 1.9s1.9-.798 1.9-1.9c0-1.102-.836-1.9-1.9-1.9s-1.9.798-1.9 1.9z" _fill="#fff"/></g><defs><filter id="filter0_d_1955_188" x=".605" y=".45" width="17.8" height="41.55" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="3.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1955_188"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1955_188" result="shape"/></filter></defs>'
  }
})
