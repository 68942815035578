import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: process.env.VUE_APP_RENDER === "prerender" ? "history" : "",
  base: process.env.VUE_APP_PATH,
  routes: [
    {
      path: "/",
      redirect: "/it/"
    },
    {
      path: "/:lang/",
      name: "landing",
      component: () => import("./views/Landing.vue")
    },
    // TODO: remove, this is only for testing purposes
    {
      path: "/:lang/splash/",
      name: "splash",
      component: () => import("./views/SplashPage.vue")
    },
    {
      path: "/:lang/privacy/",
      name: "privacy",
      component: () => import("./views/Privacy.vue")
    },
    {
      path: "/:lang/terra/",
      name: "earth3d",
      component: () => import("./views/3Dviews/Terra3D.vue")
    },
    {
      path: "/:lang/cielo/",
      name: "sky3d",
      component: () => import("./views/3Dviews/Cielo3D.vue")
    },
    {
      path: "/:lang/mare/",
      name: "sea3d",
      component: () => import("./views/3Dviews/Mare3D.vue")
    }
  ]
  /* scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {x: 0, y: 0};
      }
    }, */
});
