import Vuex from "vuex";
import Vue from "vue";
import sampleStore from "./modules/sampleStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sampleStore
  }
});
