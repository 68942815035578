/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 46,
    height: 37,
    viewBox: '0 0 46 37',
    data: '<g filter="url(#filter0_d_885_2778)" _stroke="#fff" stroke-width="2"><path pid="0" d="M7 8.5h32M7 18.5h32M7 28.5h32"/></g><defs><filter id="filter0_d_885_2778" x="0" y=".5" width="46" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="3.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_885_2778"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_885_2778" result="shape"/></filter></defs>'
  }
})
