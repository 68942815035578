/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" d="M36.75 22.75h1a1 1 0 00-1-1v1zm0-1h-7v2h7v-2zM27 24.5v8.75h2V24.5h-2zM29.75 36H35v-2h-5.25v2zm8-2.75v-10.5h-2v10.5h2zM35 36a2.75 2.75 0 002.75-2.75h-2A.75.75 0 0135 34v2zm-8-2.75A2.75 2.75 0 0029.75 36v-2a.75.75 0 01-.75-.75h-2zm2.75-11.5A2.75 2.75 0 0027 24.5h2a.75.75 0 01.75-.75v-2zM5.25 22.75v-1a1 1 0 00-1 1h1zm0 1h7v-2h-7v2zm7.75.75v8.75h2V24.5h-2zm-.75 9.5H7v2h5.25v-2zm-6-.75v-10.5h-2v10.5h2zM7 34a.75.75 0 01-.75-.75h-2A2.75 2.75 0 007 36v-2zm6-.75a.75.75 0 01-.75.75v2A2.75 2.75 0 0015 33.25h-2zm-.75-9.5a.75.75 0 01.75.75h2a2.75 2.75 0 00-2.75-2.75v2z" _fill="#fff"/><path pid="1" d="M5.25 22.75C5.25 14.052 12.302 7 21 7s15.75 7.052 15.75 15.75" _stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" _stroke="#fff" stroke-width="2" d="M35.718 4.696l-32 33"/>'
  }
})
